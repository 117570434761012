exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-assessment-index-tsx": () => import("./../../../src/pages/assessment/index.tsx" /* webpackChunkName: "component---src-pages-assessment-index-tsx" */),
  "component---src-pages-basket-recommendations-index-tsx": () => import("./../../../src/pages/basket-recommendations/index.tsx" /* webpackChunkName: "component---src-pages-basket-recommendations-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newcheckout-index-tsx": () => import("./../../../src/pages/newcheckout/index.tsx" /* webpackChunkName: "component---src-pages-newcheckout-index-tsx" */),
  "component---src-pages-order-index-tsx": () => import("./../../../src/pages/order/index.tsx" /* webpackChunkName: "component---src-pages-order-index-tsx" */),
  "component---src-pages-planrecommendation-index-tsx": () => import("./../../../src/pages/planrecommendation/index.tsx" /* webpackChunkName: "component---src-pages-planrecommendation-index-tsx" */),
  "component---src-pages-terms-and-conditions-index-tsx": () => import("./../../../src/pages/terms-and-conditions/index.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-tsx" */)
}

